.login-form {
  text-align: center;
  width: 60%;
  display: grid;
  gap: 13px;
}
.back-to-login {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #a1a5b7;
  display: flex;
  align-content: center;
  gap: 13px;
  position: absolute;
  top: 7vh;
  left: 4%;
  z-index: 1000;
}
.left-col {
  width: 50%;
}
@media (max-width: 576px) {
  .left-col {
    width: 100% !important;
  }
}
@media (max-width: 1172px) {
  .login-form {
    width: 80%;
  }
}
.login-form .ant-form-item-control-input-content {
  background: transparent;
  border: 0;
  color: #5e627832;

  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 14px; /* 107.692% */
}
.forget {
  color: var(--1-e-1-e-1-e, #1e1e1e);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 107.692% */
}
.ant-checkbox-inner {
  border-color: #00a8c0 !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00a8c0 !important;
}
.no-otp {
  color: var(--Gray-Gray-500, #a1a5b7);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}
.no-otp span {
  color: var(--Gray-Gray-500, #1e1e1e);
}

.input-otp__field {
  max-width: 80px !important;
}
